import React from 'react';
import './customerAdvert.css';

const CustomerAdvert = (props) => {

  return (
    <div data-testid='CustomerAdvert'>
      <div>details of customers advert</div>
    </div>
  );
};

export default CustomerAdvert;
