// import { toHaveAccessibleDescription } from '@testing-library/jest-dom/dist/matchers';
import { connect } from "react-redux";
import { getCurrentUserValue, getCurrentUserIDValue } from "../../stores/AuthStore/AuthStoreHelper";
import {
  getErrorMessageText,
  setErrorMessageForShow,
} from "./../../stores/ErrorMessageStore/ErrorMessageStoreHelper";
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from "./../../stores/SuccessMessageStore/SuccessMessageStoreHelper";

const Dashboard = (props) => {
  return (
    <div style={{ textAlign: "center" }} data-testid="Dashboard">
      Dashboard, current user is: {props.currentUser}
      {
        /* istanbul ignore next */
        props.errorMessage && props.errorMessage.length >= 0 ? (
          <div className="alert alert-danger" role="alert">
            {props.errorMessage}
          </div>
        ) : (
          ""
        )
      }
      {
        /* istanbul ignore next */
        props.successMessage && props.successMessage.length >= 0 ? (
          <div className="alert alert-success" role="alert">
            {props.successMessage}
          </div>
        ) : (
          ""
        )
      }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUserValue(state),
    uid: getCurrentUserIDValue(state),
    successMessage: getSuccessMessage(state),
    errorMessage: getErrorMessageText(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
