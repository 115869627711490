import { SET_NOTICE_MESSAGE, CLEAR_NOTICE_MESSAGE } from "./constantsNoticeMessageStore";

export function setNoticeMessage(noticeMessage) {
  return {
    type: SET_NOTICE_MESSAGE,
    payload: noticeMessage,
  };
}

export function clearNoticeMessage() {
  return {
    type: CLEAR_NOTICE_MESSAGE,
    payload: "",
  };
}
