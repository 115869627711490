import { SUCCESS_MESSAGE } from "../../constants/constans.store";
import { setSuccessMessage, clearSuccessMessage } from "./SuccessMessageAction";

export const getSuccessMessage = (state) => {
  return state?.[SUCCESS_MESSAGE]?.[SUCCESS_MESSAGE] ? state[SUCCESS_MESSAGE][SUCCESS_MESSAGE] : "";
};

export const setSuccessMessageForShow = (dispatch, successMessage) => {
  dispatch(setSuccessMessage(successMessage));
  setTimeout(() => {
    dispatch(clearSuccessMessage());
  }, 5000);
};
