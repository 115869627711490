export const YES = "YES";
export const NO = "NO";
export const TYPE = "type";
export const TEXT = "text";
export const NUMBER = "number";
export const PRODUCTION = "production";
export const DEVELOPMENT = "development";
export const AUTOLOGIN = "AutoLogin";
export const CURRENT_USER = "currentUser";
export const STATE = "state";
export const AUTH_TIME = "authTime";
export const DELTA_AUTH_EXPIRED_MILLISECONDS = 2 * 60 * 60 * 1000;
export const AUTH_PERIOD_HAS_BEEN_EXPIRED = "Auth period has been expired";
export const DELTA_AUTH_WILL_BE_EXPIRED_MILLISECONDS = 1 * 60 * 60 * 1000;
export const AUTH_PERIOD_WILL_BE_EXPIRED = "Auth period will be expired soon. Please re-auth.";
export const DELTA_REMOTE_CONFIG_MILLISECONDS = 15 * 60 * 1000;
export const REMOTE_CONFIG_TIME = "remoteConfigTime";
export const API_WILL_SHUTDOWN_SOON = "API will shutdown soon";
export const API_IS_SHUTDOWN_NOW = "API is shutdown now";
export const DELTA_REMOTE_CONFIG_REFETCH_MILLISECONDS = 1 * 30 * 1000;
export const NO_BLOCKERS_ARE_SET = "No blockers are set";
export const ERROR_MESSAGE_NO_API_URL = "No API URL has been provided";
export const INPUT_WRONG_VALUE = "inputWrongValue";
export const INPUT_CORRECT_VALUE = "inputCorrectValue";
