import React, { useEffect } from "react";
import "./CountDown.scss";
import { DELTA_REMOTE_CONFIG_MILLISECONDS, NO_BLOCKERS_ARE_SET } from "../../constants/constants";
import { REMOTE_CONFIG_UPDATED_TIME } from "../../constants/constants.datamodel";

const CountDown = (props) => {
  const [countDownInterval, setCountDownInterval] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const interval =
        props.blockerIsActive === true
          ? Math.round(
              (new Date().getTime() - props.startMoment[REMOTE_CONFIG_UPDATED_TIME]) / 1000
            )
          : NO_BLOCKERS_ARE_SET;

      setCountDownInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDownInterval, props.blockerIsActive]);

  const minutesSeconds = () => {
    const seconds = countDownInterval % 60 || 0;
    const minutes = (countDownInterval - seconds) / 60 || 0;

    return isNaN(countDownInterval) ? NO_BLOCKERS_ARE_SET : `${minutes} : ${seconds}`;
  };

  return (
    <React.Fragment>
      <span
        className={
          countDownInterval < DELTA_REMOTE_CONFIG_MILLISECONDS / 1000 ? "redText" : "greenText"
        }
        data-testid="CountDown"
      >
        {minutesSeconds(countDownInterval)}
      </span>
    </React.Fragment>
  );
};

export default CountDown;
