import React from "react";
import { useEffect, useState } from "react";
import CustomersList from "./CustomersList/CustomersList";
import { useNavigate } from "react-router";
import { NEW, EDIT, CUSTOMERS_ROUTE } from "./../../constants/constants.routes";
import { getHostResponseObject } from "../../stateObjects/hostResponse.model";

const Customers = (props) => {
  let navigate = useNavigate();

  const hostResponseObject = getHostResponseObject();
  const [customersList, setCustomersList] = useState(hostResponseObject);

  const addNewCustomer = () => {
    navigate(CUSTOMERS_ROUTE + EDIT + "/" + NEW, { replace: true });
  };

  useEffect(() => {
    setCustomersList({ ...hostResponseObject, ...props.customersList });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customersList]);

  return (
    <React.Fragment>
      <div className="container-fluid text-center mt-5 w-25">
        <div className="section-title" data-testid="Customers">
          Customers
        </div>
        <div className="section-title buttonToRight">
          <button onClick={addNewCustomer}>Add new</button>
        </div>
        <CustomersList customersList={customersList} forceIndexPage="true" />
      </div>
    </React.Fragment>
  );
};

export default Customers;
