import React, { useEffect, useRef } from "react";
import "./userEdit.css";
import { connect } from "react-redux";
import {
  getFormCheckStatus,
  getUserForUpdate,
} from "./../../../stores/BrainStore/BrainsStoreHelper";
import {
  setUserForEditStore,
  setForceUpdateStore,
  setFormCheckStatusStore,
} from "./../../../stores/BrainStore/BrainsAction";
import { getForceUpdate } from "./../../../stores/BrainStore/BrainsStoreHelper";
import { setUserPermissions } from "./../../../service/Firebase/userPermissions.helper.firebase";
import {
  getErrorMessageText,
  setErrorMessageForShow,
} from "./../../../stores/ErrorMessageStore/ErrorMessageStoreHelper";
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from "./../../../stores/SuccessMessageStore/SuccessMessageStoreHelper";
import {
  REMOVELEADINGZEROS,
  USERFIELDNAME,
  MINVALUE,
  MAXVALUE,
} from "../../../constants/constants.validationResult";
import {
  ENDTIME,
  STARTTIME,
  USER_EMAIL,
  WEEK_DAYS,
  WEEK_END,
} from "../../../constants/constants.datamodel";
import { getRefCheckStatus, validateField } from "../../../service/Validator/validate.field";
import { NUMBER, TYPE } from "../../../constants/constants";
import { getNewUserPermissionsObject } from "../../../service/Models/UserPermissions.model";
import { getUser } from "../../../service/Firebase/userList.helper.firebase";
import { useNavigate } from "react-router";
import * as CONSTANTS from "../../../constants/constants.routes";
import { PERMISSIONS } from "../../../stores/AuthStore/constantsAuthStore";

const UserEdit = (props) => {
  let navigate = useNavigate();

  const defaultPermissionObject = getNewUserPermissionsObject();
  const [userForUpdate, setUserForUpdate] = React.useState(defaultPermissionObject);
  const startTimeInputRef = useRef(null);
  const endTimeInputRef = useRef(null);
  const validateTimeField = (fieldRef) => {
    validateFieldValue(fieldRef, {
      [USERFIELDNAME]: fieldRef.current.name,
      [REMOVELEADINGZEROS]: true,
      [TYPE]: NUMBER,
      [MINVALUE]: 0,
      [MAXVALUE]: 24,
    });
  };

  const validateForm = () => {
    const checkStatusObject = {
      [STARTTIME]: false,
      [ENDTIME]: false,
    };

    if (checkStatusObject[STARTTIME] !== getRefCheckStatus(startTimeInputRef)) {
      checkStatusObject[STARTTIME] = getRefCheckStatus(startTimeInputRef);
    }

    if (checkStatusObject[ENDTIME] !== getRefCheckStatus(endTimeInputRef)) {
      checkStatusObject[ENDTIME] = getRefCheckStatus(endTimeInputRef);
    }

    let formHasErrors = Object.keys(checkStatusObject).map((key) => {
      if (checkStatusObject[key] === false) {
        return 1;
      }
      return false;
    });

    formHasErrors = formHasErrors.filter((element) => element > 0);

    return formHasErrors.length > 0 ? false : true;
  };

  const checkBoxClick = (event, property) => {
    const tmpUserForUpdate = Object.assign({}, userForUpdate);

    if (userForUpdate.hasOwnProperty(property)) {
      tmpUserForUpdate[property] = !userForUpdate[property];
    } else {
      tmpUserForUpdate[PERMISSIONS][property] = !userForUpdate[PERMISSIONS][property];
    }

    setUserForUpdate(tmpUserForUpdate);
  };

  const validateFieldValue = async (fieldRef, options) => {
    const validationResult = await validateField(
      fieldRef,
      options,
      props.setSuccessMessage,
      props.setErrorMessage
    );

    const objectToUpdate = Object.assign({}, userForUpdate);

    if (objectToUpdate[fieldRef.current.name] !== fieldRef.current.value) {
      objectToUpdate[fieldRef.current.name] = fieldRef.current.value;
      objectToUpdate["validation"] = validationResult;
      setUserForUpdate(objectToUpdate);
    }
  };

  const saveButtonClick = async () => {
    delete userForUpdate.maxUserLevelName;
    delete userForUpdate.validation;

    userForUpdate[STARTTIME] = Number(userForUpdate[STARTTIME]);
    userForUpdate[ENDTIME] = Number(userForUpdate[ENDTIME]);

    await setUserPermissions(userForUpdate.uid, userForUpdate)
      .then((result) => {
        props.setSuccessMessage(result);
      })
      .catch((error) => {
        props.setErrorMessage(error.message);
      });
  };

  useEffect(() => {
    async function fetchUser() {
      if (!props?.userForUpdate?.uid) {
        navigate(CONSTANTS.USERS, { replace: true });
      }

      const result = await getUser(String(props?.userForUpdate?.uid));

      setUserForUpdate(result || defaultPermissionObject);
    }

    fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startTimeInputRef.current.value !== userForUpdate[STARTTIME]) {
      startTimeInputRef.current.value = userForUpdate[STARTTIME];
      validateTimeField(startTimeInputRef);
    }

    if (endTimeInputRef.current.value !== userForUpdate[ENDTIME]) {
      endTimeInputRef.current.value = userForUpdate[ENDTIME];
      validateTimeField(endTimeInputRef);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForUpdate]);

  return (
    <div className="container-fluid text-center mt-5 w-25">
      Edit permissions for <br />
      <b>{userForUpdate[USER_EMAIL]}</b>
      <div className="mt-2">
        <div>
          working time from
          <input
            type="string"
            ref={startTimeInputRef}
            name="startTime"
            onChange={(event) => {
              validateTimeField(startTimeInputRef);
            }}
            size="5"
            maxLength="5"
            id="startTime"
          />
          till
          <input
            type="string"
            ref={endTimeInputRef}
            name="endTime"
            onChange={(event) => {
              validateTimeField(endTimeInputRef);
            }}
            size="5"
            maxLength="5"
            id="endTime"
          />
        </div>
        <div>
          working days
          <div
            className="left-text checkbox-permissions"
            onClick={(event) => checkBoxClick(event, WEEK_DAYS)}
          >
            <input
              type="checkbox"
              id={WEEK_DAYS}
              name={WEEK_DAYS}
              onChange={() => {}}
              checked={userForUpdate[WEEK_DAYS] ? true : false}
            />
            <label
              htmlFor={WEEK_DAYS}
              className="checkbox-permissions-label"
              onClick={(event) => {
                checkBoxClick(event, WEEK_DAYS);
              }}
            >
              Monday - Friday ({WEEK_DAYS})
            </label>
          </div>
        </div>

        <div
          className="left-text checkbox-permissions"
          onClick={(event) => checkBoxClick(event, WEEK_END)}
        >
          <input
            type="checkbox"
            id={WEEK_END}
            name={WEEK_END}
            onChange={() => {}}
            checked={userForUpdate[WEEK_END] ? true : false}
          />
          <label
            htmlFor={WEEK_END}
            className="checkbox-permissions-label"
            onClick={(event) => {
              checkBoxClick(event, WEEK_END);
            }}
          >
            Sat - Sun ({WEEK_END})
          </label>
        </div>
      </div>
      <div className="mt-3">
        Assigned roles
        {Object.keys(userForUpdate[PERMISSIONS]).map((key) => {
          return (
            <div
              className="left-text checkbox-permissions"
              key={key}
              onClick={(event) => checkBoxClick(event, key)}
            >
              <input
                type="checkbox"
                id={key}
                name={key}
                onChange={() => {}}
                checked={userForUpdate[PERMISSIONS][key]}
              />
              <label
                htmlFor={key}
                className="checkbox-permissions-label"
                onClick={(event) => {
                  checkBoxClick(event, key);
                }}
              >
                {key} : {userForUpdate[PERMISSIONS][key] ? "true" : "false"}
              </label>
            </div>
          );
        })}
      </div>
      <div className="mt-5">
        <button
          disabled={!validateForm()}
          onClick={() => {
            saveButtonClick();
          }}
        >
          Save
        </button>
      </div>
      <div className="p-3">
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className="alert alert-danger" role="alert">
              {props.errorMessage}
            </div>
          ) : (
            ""
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className="alert alert-success" role="alert">
              {props.successMessage}
            </div>
          ) : (
            ""
          )
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserForEdit: (user) => {
      dispatch(setUserForEditStore(user));
    },
    setForceUpdate: (forceUpdate) => {
      dispatch(setForceUpdateStore(forceUpdate));
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
    setFormCheckStatus: (formCheckStatus) => {
      dispatch(setFormCheckStatusStore(formCheckStatus));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    userForUpdate: getUserForUpdate(state),
    forceUpdate: getForceUpdate(state),
    errorMessage: getErrorMessageText(state),
    successMessage: getSuccessMessage(state),
    formCheckStatus: getFormCheckStatus(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
