import React, { useEffect, useState } from 'react';
import { getNewRemoteConfigRTDBObject } from '../../service/Models/RemoteConfigRCDB.model';
import {
  setRemoteConfigRCDB,
  getRemoteConfigRCDB,
  checkIfBlockerIsActive,
} from '../../service/Firebase/remoteConfigRCDB.helper.firebase';
import {
  getErrorMessageText,
  setErrorMessageForShow,
} from '../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from '../../stores/SuccessMessageStore/SuccessMessageStoreHelper';
import { connect } from 'react-redux';
import {
  BLOCKER_IS_ACTIVE,
  OPTIONS,
  REMOTE_CONFIG_OBJECT,
  REMOTE_CONFIG_UPDATED_TIME,
} from '../../constants/constants.datamodel';
import CountDown from '../CountDown/CountDown';

const RemoteConfigRCDB = (props) => {
  const defaultRemoteConfigDBObject = getNewRemoteConfigRTDBObject();
  const [RCDB, setRCDB] = React.useState({
    [REMOTE_CONFIG_OBJECT]: defaultRemoteConfigDBObject,
    [BLOCKER_IS_ACTIVE]: false,
  });

  const [render, rerender] = useState(false);

  useEffect(() => {
    async function fetchRemoteConfig() {
      const result = await getRemoteConfigRCDB();
      RCDB[REMOTE_CONFIG_OBJECT] = result;
      RCDB[BLOCKER_IS_ACTIVE] = checkIfBlockerIsActive(RCDB);

      setRCDB(RCDB);
      rerender(!render);
    }

    fetchRemoteConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkBoxClick = (event, property) => {
    const tmpRemoteConfigDBObject = Object.assign(
      {},
      RCDB[REMOTE_CONFIG_OBJECT]
    );
    tmpRemoteConfigDBObject[OPTIONS][property] =
      !RCDB[REMOTE_CONFIG_OBJECT][OPTIONS][property];
    event.nativeEvent.stopImmediatePropagation();

    RCDB[REMOTE_CONFIG_OBJECT] = tmpRemoteConfigDBObject;
    RCDB[BLOCKER_IS_ACTIVE] = checkIfBlockerIsActive(RCDB);

    setRCDB(RCDB);
    saveButtonClick();
  };

  const saveButtonClick = async () => {
    const currentTime = new Date().getTime();
    RCDB[REMOTE_CONFIG_OBJECT][REMOTE_CONFIG_UPDATED_TIME] = currentTime;

    await setRemoteConfigRCDB(RCDB[REMOTE_CONFIG_OBJECT])
      .then((result) => {
        props.setSuccessMessage(result);
        setRCDB(RCDB);
      })
      .catch((error) => {
        props.setErrorMessage(error.message);
      });
  };

  return (
    <div
      className='container-fluid text-center mt-5 w-25'
      data-testid='RemoteConfigDB'
    >
      <div className='section-title'>Remote config</div>

      <div className='text-center fw-bold'>
        <CountDown
          startMoment={RCDB[REMOTE_CONFIG_OBJECT]}
          blockerIsActive={RCDB[BLOCKER_IS_ACTIVE]}
        />
      </div>

      {Object.keys(RCDB[REMOTE_CONFIG_OBJECT][OPTIONS]).map((key) => {
        return (
          <div
            className='left-text checkbox-permissions'
            key={key}
            onClick={(event) => checkBoxClick(event, key)}
          >
            <input
              type='checkbox'
              id={key}
              name={key}
              onChange={() => {}}
              checked={RCDB[REMOTE_CONFIG_OBJECT][OPTIONS][key]}
            />
            <label
              htmlFor={key}
              className='checkbox-permissions-label'
              onClick={(event) => {
                checkBoxClick(event, key);
              }}
            >
              {key} :{' '}
              {RCDB[REMOTE_CONFIG_OBJECT][OPTIONS][key] ? 'true' : 'false'}
            </label>
          </div>
        );
      })}

      <div className='p-3'>
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className='alert alert-danger' role='alert'>
              {props.errorMessage}
            </div>
          ) : (
            ''
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className='alert alert-success' role='alert'>
              {props.successMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getErrorMessageText(state),
    successMessage: getSuccessMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteConfigRCDB);
