import { textValidator } from "./text.validator";
import { numberValidator } from "./number.validator";
import {
  TEXT,
  NUMBER,
  TYPE,
  INPUT_WRONG_VALUE,
  INPUT_CORRECT_VALUE,
} from "../../constants/constants";
import { RESULT, REMOVELEADINGZEROS, VALUE } from "../../constants/constants.validationResult";
import { STATUS } from "../../constants/constants.datamodel";
import { getNewValidationResultObjectObject } from "../Models/ValidatorResult.model";

export const validateField = async (fieldRef, options, setSuccessMessage, setErrorMessage) => {
  let validationResult = getNewValidationResultObjectObject();

  switch (options[TYPE]) {
    case TEXT: {
      validationResult = textValidator(fieldRef.current.value, options);
      break;
    }
    case NUMBER: {
      validationResult = numberValidator(fieldRef.current.value, options);

      if (options[REMOVELEADINGZEROS] && options[REMOVELEADINGZEROS]) {
        fieldRef.current.value = validationResult[VALUE];
      }

      break;
    }
    default: {
      setErrorMessage("Unknown field type");
    }
  }

  if (validationResult[RESULT]) {
    fieldRef.current.classList.remove(INPUT_WRONG_VALUE);
    fieldRef.current.classList.add(INPUT_CORRECT_VALUE);
    setSuccessMessage(validationResult[STATUS]);
  } else {
    fieldRef.current.classList.remove(INPUT_CORRECT_VALUE);
    fieldRef.current.classList.add(INPUT_WRONG_VALUE);
    setErrorMessage(validationResult[STATUS]);
  }

  return validationResult[RESULT];
};

export const getRefCheckStatus = (ref) => {
  if (ref && ref?.current?.className?.includes(INPUT_WRONG_VALUE)) {
    return false;
  } else if (ref && ref?.current?.className?.includes(INPUT_CORRECT_VALUE)) {
    return true;
  }
  return undefined;
};
