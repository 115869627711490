import { database } from "./Firebase";
import { ref, get, set } from "firebase/database";
import {
  getNewUserPermissionsObject,
  userPermissionsObjectSetStatus,
} from "../Models/UserPermissions.model";
import { GRANTED, STATUS, USER_EMAIL } from "../../constants/constants.datamodel";
import { QUARANTINE, USERS } from "../../constants/constants.firebase.database";
import { getUserPermissionsLevel } from "accesslevel";
import { PERMISSIONS } from "../../stores/AuthStore/constantsAuthStore";

export async function getUsersPermissions(userEmail, uid) {
  let userPermissions = getNewUserPermissionsObject();

  if (!uid) {
    return userPermissions;
  }

  const snapshot = await get(ref(database, `/${USERS}/${uid}`))
    .then((result) => {
      return result;
    })
    .catch(() => {
      return null;
    });

  let snapshotData = null;

  if (snapshot) {
    snapshotData = await snapshot.val();
  }

  if (!snapshotData) {
    userPermissions[USER_EMAIL] = userEmail;
    const status = await setUserPermissions(uid, userPermissions, QUARANTINE);
    userPermissionsObjectSetStatus(userPermissions, status);
    throw new Error("New user has been added to Database");
  } else {
    snapshotData[STATUS] = "auth successful!";
    snapshotData[USER_EMAIL] = userEmail;
  }

  userPermissions = Object.assign(userPermissions, snapshotData);

  return userPermissions;
}

export async function setUserPermissions(uid, userPermissionsObject, table = USERS) {
  if (table === USERS) {
    userPermissionsObject[GRANTED] =
      getUserPermissionsLevel(userPermissionsObject[PERMISSIONS]) > 0;
  }

  const statusOfWritingUserPermissions = await set(
    ref(database, `/${table}/${uid}`),
    userPermissionsObject
  )
    .then((result) => {
      return "record has been added(saved) to Users";
    })
    .catch((error) => {
      throw new Error("issue with adding user to Firebase Realtime Database:" + error);
    });

  return statusOfWritingUserPermissions;
}
