import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import * as CONSTANTS from '../../constants/constants.routes';
import SignIn from '../SignIn/SignIn';
import SignOut from '../SignOut/SignOut';
import NoPermissionGranted from '../NoPermissionsGranted/NoPermissionsGranted';
import Dashboard from '../Dashboard/Dashboard';
import { connect } from 'react-redux';
import { getCurrentUserValue } from '../../stores/AuthStore/AuthStoreHelper';
import Users from '../Users/Users';
import UserEdit from '../Users/UserEdit/UserEdit';
import CustomerAdvert from '../Customers/CustomerAdvert/CustomerAdvert';
import RemoteConfigDB from '../RemoteConfigRCDB/remoteConfigRCDB';
import CustomersEditWrapper from '../Customers/CustomerEditWrapper/CustomerEditWrapper';
import CheckStatusWrapper from '../CheckStatus/CheckStatusWrapper/CheckStatusWrapper';
import CustomersWrapper from '../Customers/CustomersWrapper';

const RoutesArray = (props) => {
  return (
    <Router>
      <Navigation currentUser={props.currentUser} />
      <hr />
      <Routes>
        <Route
          path={CONSTANTS.LANDING}
          element={props.currentUser ? <Dashboard /> : <NoPermissionGranted />}
        />
        <Route
          path={CONSTANTS.CUSTOMERS_ROUTE}
          element={<CustomersWrapper />}
          exact
        />
        <Route path={CONSTANTS.USERS} element={<Users />} exact />
        <Route
          path={CONSTANTS.REMOTE_CONFIG}
          element={<RemoteConfigDB />}
          exact
        />
        <Route path='/status/:host' element={<CheckStatusWrapper />} exact />
        <Route path='/status/:host' element={<CheckStatusWrapper />} exact />

        <Route path={CONSTANTS.AUTH_SIGNIN} element={<SignIn />} exact />
        <Route path={CONSTANTS.AUTH_SIGNOUT} element={<SignOut />} exact />
        <Route
          path={`${CONSTANTS.AUTH_SIGNOUT}/:${CONSTANTS.FORCED}`}
          element={<SignOut />}
          exact
        />
        <Route
          path={`${CONSTANTS.USERS}/:userEmail`}
          element={<UserEdit />}
          exact
        />
        <Route
          path={`${CONSTANTS.CUSTOMERS_ROUTE}${CONSTANTS.EDIT}/:customerId`}
          element={<CustomersEditWrapper />}
          exact
        />
        <Route
          path={`${CONSTANTS.CUSTOMERS_ROUTE}${CONSTANTS.ADVERT}/:customerId`}
          element={<CustomerAdvert />}
          exact
        />
      </Routes>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: getCurrentUserValue(state) };
};

export default connect(mapStateToProps, null)(RoutesArray);
