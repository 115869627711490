import { FORCE_SIGN_OUT } from "../../constants/constants.hostResponseObject";
import {
  SET_ERROR_MESSAGE,
  CLEAR_ERROR_MESSAGE,
  SET_FORCE_SIGN_OUT,
  CLEAR_FORCE_SIGN_OUT,
} from "./constantsErrorMessageStore";
import { ERROR_MESSAGE } from "../../constants/constans.store";

const initialState = {
  [ERROR_MESSAGE]: "",
  [FORCE_SIGN_OUT]: false,
  component: "",
};

export const errorMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return { ...state, [ERROR_MESSAGE]: action.payload };
    case CLEAR_ERROR_MESSAGE:
      return { ...state, [ERROR_MESSAGE]: action.payload };
    case SET_FORCE_SIGN_OUT:
      return { ...state, [FORCE_SIGN_OUT]: true };
    case CLEAR_FORCE_SIGN_OUT:
      return { ...state, [FORCE_SIGN_OUT]: false };
    default:
      return state;
  }
};
