import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHttp } from "../../hooks/http.hook";
import { getHostResponseObject } from "../../stateObjects/hostResponse.model";
import { CUSTOMERS_ENDPOINT } from "../../constants/constants.api";
import { ERROR_MESSAGE, FORCE_SIGN_OUT } from "../../constants/constants.hostResponseObject";
import { setSpinnerStatusOn, setSpinnerStatusOff } from "../../stores/SpinnerStore/SpinnerAction";
import {
  getErrorMessageText,
  getForceSignOutStore,
  setErrorMessageForShow,
  setForceSignOutStore,
} from "./../../stores/ErrorMessageStore/ErrorMessageStoreHelper";
import { setNoticeMessageForShow } from "../../stores/NoticeMessageStore/NoticeMessageStoreHelper";
import SignOut from "../SignOut/SignOut";
import Customers from "./Customers";

const CustomersWrapper = (props) => {
  const hostResponseObject = getHostResponseObject();
  const [customersList, setCustomersList] = useState(hostResponseObject);
  const [loading, request] = useHttp();

  useEffect(() => {
    async function getCustomersListRequest() {
      try {
        const result = await request(process.env.REACT_APP_API_URL + CUSTOMERS_ENDPOINT, "POST");

        setCustomersList({ ...hostResponseObject, ...result });

        if (result[ERROR_MESSAGE]) {
          props.setErrorMessage(result[ERROR_MESSAGE]);

          if (result[FORCE_SIGN_OUT]) {
            throw result[ERROR_MESSAGE];
          }
        }
      } catch (e) {
        props.setNoticeMessage(e);
        props.setForceSignOutStore();
      }
    }

    getCustomersListRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      props.setSpinnerOn();
    } else {
      props.setSpinnerOff();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <React.Fragment>
      {props.forceSignOut === true ? <SignOut /> : <Customers customersList={customersList} />}

      <div className="p-3">
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className="alert alert-danger" role="alert">
              {props.errorMessage}
            </div>
          ) : (
            ""
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className="alert alert-success" role="alert">
              {props.successMessage}
            </div>
          ) : (
            ""
          )
        }
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpinnerOn: () => {
      dispatch(setSpinnerStatusOn());
    },
    setSpinnerOff: () => {
      dispatch(setSpinnerStatusOff());
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setNoticeMessage: (noticeMessage) => {
      setNoticeMessageForShow(dispatch, noticeMessage);
    },
    setForceSignOutStore: () => {
      setForceSignOutStore(dispatch);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getErrorMessageText(state),
    forceSignOut: getForceSignOutStore(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersWrapper);
