import React, {useState, useEffect} from 'react';
import './customer.css';
import { EDIT_BUTTON } from '../../../constants/constants.titles';
import { CUSTOMER_NAME, GOOGLE_ID } from '../../../constants/constants.customer';

const Customer = (props) => {
  const [customerElement, setCustomerElement] = useState({});

  useEffect(() => {
    setCustomerElement({ ...props.customerElement });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customerElement]);

  return (
    <div data-testid='Customer'>
      <div
        key={customerElement.id}
        className='formLine'
        onClick={() => props.customerAdvertAction(customerElement)}
      >
        <div>#{props.customerElement.id}</div>
        <div className='bold-text'>{customerElement[CUSTOMER_NAME]}</div>
        <div>Adwords ID: # <span>{customerElement[GOOGLE_ID]}</span></div>
        <div>
          <button
            className='buttonSection'
            onClick={(event) => {
              props.customerEditAction(event, customerElement);
            }}
          >
            {EDIT_BUTTON}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Customer;
