import React, { useCallback } from "react";
import {
  CURRENT_USER,
  AUTH_TIME,
  DELTA_AUTH_EXPIRED_MILLISECONDS,
  AUTH_PERIOD_HAS_BEEN_EXPIRED,
  DELTA_AUTH_WILL_BE_EXPIRED_MILLISECONDS,
  AUTH_PERIOD_WILL_BE_EXPIRED,
  REMOTE_CONFIG_TIME,
  DELTA_REMOTE_CONFIG_REFETCH_MILLISECONDS,
} from "../constants/constants";
import { AUTH_HEADER, FE_VERSION_HEADER } from "../constants/constants.api";
import { STATUS } from "../constants/constants.datamodel";
import { MESSAGE, ERROR_MESSAGE, FORCE_SIGN_OUT } from "../constants/constants.hostResponseObject";
import { getHostResponseObject } from "../stateObjects/hostResponse.model";
import { getRemoteConfigRCDB } from "../service/Firebase/remoteConfigRCDB.helper.firebase";
import { maintenanceApplicationStatusIsBlocked } from "../service/Models/RemoteConfigRCDB.model";

export function useHttp() {
  let hostResponseObject = getHostResponseObject();
  const [loading, setLoading] = React.useState(false);
  const request = useCallback(async (url, method = "GET", body = null, headers = {}) => {
    setLoading(true);

    const [currentUserId] = getCurrentUserId();

    headers[FE_VERSION_HEADER] = process.env.REACT_APP_VERSION;

    if (currentUserId) {
      headers[AUTH_HEADER] = currentUserId;
    } else {
      hostResponseObject[ERROR_MESSAGE] = "UserId is missed";
      setLoading(false);

      return hostResponseObject;
    }

    if (getAuthTimeDeltaTimestamp() > DELTA_AUTH_WILL_BE_EXPIRED_MILLISECONDS) {
      hostResponseObject[ERROR_MESSAGE] = AUTH_PERIOD_WILL_BE_EXPIRED;
      hostResponseObject[FORCE_SIGN_OUT] = false;
    }

    if (getAuthTimeDeltaTimestamp() > DELTA_AUTH_EXPIRED_MILLISECONDS) {
      hostResponseObject[ERROR_MESSAGE] = AUTH_PERIOD_HAS_BEEN_EXPIRED;
      hostResponseObject[FORCE_SIGN_OUT] = true;
      setLoading(false);

      return hostResponseObject;
    }

    if (getRemoteConfigDeltaTimestamp() > DELTA_REMOTE_CONFIG_REFETCH_MILLISECONDS) {
      setRemoteConfigTimestampInSessionStorage();

      const remoteConfigRCDB = await getRemoteConfigRCDB();
      const isSystemUnderMaintenanceStatus = maintenanceApplicationStatusIsBlocked(
        Object.assign({}, remoteConfigRCDB)
      );
      if (isSystemUnderMaintenanceStatus) {
        hostResponseObject[ERROR_MESSAGE] = "System is under maintenance. Please try later";
        setLoading(false);

        return hostResponseObject;
      }

      setRemoteConfigTimestampInSessionStorage();
    }

    try {
      const response = await fetch(url, { method, body, headers });
      let data = await response.json();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hostResponseObject = { ...hostResponseObject, ...data };

      if (hostResponseObject[STATUS] !== 200) {
        hostResponseObject[MESSAGE] = "";
      }
    } catch (e) {
      hostResponseObject[ERROR_MESSAGE] = e.message;
    }

    setLoading(false);

    return hostResponseObject;
  }, []);

  return [loading, request];
}

const getCurrentUserId = () => {
  const currentUserObjectItem = window.sessionStorage.getItem(CURRENT_USER);
  let currentUserObject = null;

  if (currentUserObjectItem) {
    try {
      currentUserObject = JSON.parse(currentUserObjectItem);
    } catch (error) {
      console.log("cannot parse CurrentUser object, error:", error);
    }
  }

  return [currentUserObject ? currentUserObject?.uid : null];
};

export const setTimestampAuthTimeInSessionStorage = () => {
  const currentTime = new Date().getTime();
  window.sessionStorage.setItem(AUTH_TIME, currentTime);
};

export const getCurrentTimestamp = () => {
  const currentTime = new Date().getTime();

  return currentTime;
};

export const getAuthTimeDeltaTimestamp = () => {
  const currentTime = new Date().getTime();
  const storedTimestamp = window.sessionStorage.getItem(AUTH_TIME) || 0;

  return currentTime - storedTimestamp;
};

export const setRemoteConfigTimestampInSessionStorage = () => {
  const currentTime = new Date().getTime();
  window.sessionStorage.setItem(REMOTE_CONFIG_TIME, currentTime);
};

export const getRemoteConfigDeltaTimestamp = () => {
  const currentTime = new Date().getTime();
  const storedTimestamp = window.sessionStorage.getItem(REMOTE_CONFIG_TIME) || 0;

  return currentTime - storedTimestamp;
};
