import { SET_FORCE_UPDATE, SET_USER_FOR_EDIT, SET_FORM_CHECK_STATUS, USER_FOR_UPDATE, FORCE_UPDATE, FORM_CHECK_STATUS } from "./constantsBrainStore";

const initialState = {
  [FORCE_UPDATE]: 0,
  [USER_FOR_UPDATE]: null,
  [FORM_CHECK_STATUS]: {},
};

export const brainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORCE_UPDATE:
      return { ...state, [FORCE_UPDATE]: action.payload };
    case SET_USER_FOR_EDIT:
      return { ...state, [USER_FOR_UPDATE]: action.payload };
    case SET_FORM_CHECK_STATUS:
      return { ...state, [FORM_CHECK_STATUS]: action.payload };
    default:
      return state;
  }
};
