import { database } from './Firebase';
import { RemoteConfigRCDB } from '../../constants/constants.firebase.database';
import { ref, get, set } from 'firebase/database';
import { getNewRemoteConfigRTDBObject } from '../Models/RemoteConfigRCDB.model';
import {
  OPTIONS,
  REMOTE_CONFIG_OBJECT,
} from '../../constants/constants.datamodel';

export async function setRemoteConfigRCDB(remoteConfigRCDBObject) {
  const statusOfWritingRemoteConfigRTDBObject = await set(
    ref(database, `/${RemoteConfigRCDB}`),
    remoteConfigRCDBObject
  )
    .then((result) => {
      return 'statusOfWritingRemoteConfigRTDBObject has been saved';
    })
    .catch((error) => {
      throw new Error(
        'issue with saving statusOfWritingRemoteConfigRTDBObject:' + error
      );
    });

  return statusOfWritingRemoteConfigRTDBObject;
}

export async function getRemoteConfigRCDB() {
  let newRemoteConfigRTDBObject = getNewRemoteConfigRTDBObject();

  const snapshot = await get(ref(database, `/${RemoteConfigRCDB}`))
    .then((result) => {
      return result;
    })
    .catch(() => {
      return null;
    });

  let snapshotData = null;

  if (snapshot) {
    snapshotData = await snapshot.val();
  }

  const remoteConfigRTDBObject = Object.assign(
    newRemoteConfigRTDBObject,
    snapshotData
  );

  return remoteConfigRTDBObject;
}

export function checkIfBlockerIsActive(RCDB) {
  const keys = Object.keys(RCDB[REMOTE_CONFIG_OBJECT][OPTIONS]);
  let result = false;

  keys.forEach((key) => {
    if (RCDB[REMOTE_CONFIG_OBJECT][OPTIONS][key] === true) {
      result = true;
      return;
    }
  });

  return result;
}
