import { NOTICE_MESSAGE } from "../../constants/constans.store";
import { SET_NOTICE_MESSAGE, CLEAR_NOTICE_MESSAGE } from "./constantsNoticeMessageStore";

const initialState = {
  [NOTICE_MESSAGE]: "",
  component: "",
};

export const noticeMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTICE_MESSAGE:
      return { ...state, [NOTICE_MESSAGE]: action.payload };
    case CLEAR_NOTICE_MESSAGE:
      return { ...state, [NOTICE_MESSAGE]: action.payload };
    default:
      return state;
  }
};
