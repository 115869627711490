import { BRAIN_STATE } from "../../constants/constans.store";
import { FORCE_UPDATE, FORM_CHECK_STATUS, USER_FOR_UPDATE } from "./constantsBrainStore";

export const getForceUpdate = (state) => {
  return state?.[BRAIN_STATE]?.[FORCE_UPDATE] ? state[BRAIN_STATE][FORCE_UPDATE] : 0;
};

export const getUserForUpdate = (state) => {
  return state?.[BRAIN_STATE]?.[USER_FOR_UPDATE] ? state[BRAIN_STATE][USER_FOR_UPDATE] : null;
};

export const getFormCheckStatus = (state) => {
  return state?.[BRAIN_STATE]?.[FORM_CHECK_STATUS] ? state[BRAIN_STATE][FORM_CHECK_STATUS] : {};
};
