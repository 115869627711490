import { CID, CUSTOMER_NAME, GOOGLE_ID } from "../../constants/constants.customer";

export const CustomerPermissionObject = {
  [CID]: 0,
  [CUSTOMER_NAME]: "",
  [GOOGLE_ID]: "",
};

export const getNewCustomerObject = () => {
  return Object.assign({}, CustomerPermissionObject);
};

export const isCustomerValid = (customer) => {
  if (!customer) {
    return false;
  }

  if (!customer[CUSTOMER_NAME]) {
    return false;
  }

  if (!customer[GOOGLE_ID]) {
    return false;
  }

  if (customer[CUSTOMER_NAME].length < 3 || customer[CUSTOMER_NAME].length > 25) {
    return false;
  }

  return true;
};
