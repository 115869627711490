import React, { useEffect, useState } from 'react';
import CheckStatus from '../CheckStatus/CheckStatus';
import {
  ERROR_MESSAGE,
  FORCE_SIGN_OUT,
} from '../../../constants/constants.hostResponseObject';
import { useHttp } from '../../../hooks/http.hook';
import { useLocation } from 'react-router-dom';
import { getURLparams } from '../../../utils/URLutils';
import { getHostResponseObject } from '../../../stateObjects/hostResponse.model';
import {
  getErrorMessageText,
  getForceSignOutStore,
  setErrorMessageForShow,
  setForceSignOutStore,
} from './../../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import {
  setSpinnerStatusOn,
  setSpinnerStatusOff,
} from '../../../stores/SpinnerStore/SpinnerAction';
import { setNoticeMessageForShow } from '../../../stores/NoticeMessageStore/NoticeMessageStoreHelper';
import { connect } from 'react-redux';
import { STATUS_ENDPOINT } from '../../../constants/constants.api';
import { AGATA, REHOBOAM } from '../../../constants/constants.routes';
import { ERROR_MESSAGE_NO_API_URL } from '../../../constants/constants';

const CheckStatusWrapper = (props) => {
  const hostResponseObject = getHostResponseObject();
  const [checkStatusData, setCheckStatusData] = useState(hostResponseObject);
  const [loading, request] = useHttp();

  const location = useLocation();

  const params = getURLparams(location.pathname, ['host']);
  const { host } = params;

  useEffect(() => {
    checkStatusRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      props.setSpinnerOn();
    } else {
      props.setSpinnerOff();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const checkStatusRequest = async () => {
    try {
      let requestURL = '';

      switch (host) {
        case AGATA: {
          requestURL = process.env.REACT_APP_API_URL_AGATA + STATUS_ENDPOINT;
          break;
        }
        case REHOBOAM: {
          requestURL = process.env.REACT_APP_API_URL_REHOBOAM + STATUS_ENDPOINT;
          break;
        }
        default: {
          throw ERROR_MESSAGE_NO_API_URL;
        }
      }

      const result = await request(requestURL, 'POST');

      setCheckStatusData({ ...hostResponseObject, ...result });

      if (result[ERROR_MESSAGE]) {
        props.setErrorMessage(result[ERROR_MESSAGE]);
        props.setNoticeMessage(result[ERROR_MESSAGE]);
        props.setSpinnerOff();

        if (result[FORCE_SIGN_OUT]) {
          throw result[ERROR_MESSAGE];
        }
      }
    } catch (error) {
      props.setForceSignOutStore();
    }
  };

  return (
    <React.Fragment>
      <div data-testid='CheckStatusWrapper'>
        <CheckStatus
          checkStatusData={checkStatusData}
          host={host}
          forceSignOut={props.forceSignOut}
          key={host}
        />
      </div>
      <div className='p-3'>
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className='alert alert-danger' role='alert'>
              {props.errorMessage}
            </div>
          ) : (
            ''
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className='alert alert-success' role='alert'>
              {props.successMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSpinnerOn: () => {
      dispatch(setSpinnerStatusOn());
    },
    setSpinnerOff: () => {
      dispatch(setSpinnerStatusOff());
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setNoticeMessage: (noticeMessage) => {
      setNoticeMessageForShow(dispatch, noticeMessage);
    },
    setForceSignOutStore: () => {
      setForceSignOutStore(dispatch);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getErrorMessageText(state),
    forceSignOut: getForceSignOutStore(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckStatusWrapper);
