export const SERVICE_MANAGER = "serviceManager";
export const ACCOUNT_MANAGER = "accountManager";
export const SUPERVISOR = "supervisor";
export const STATUS = "status";
export const USER_EMAIL = "userEmail";
export const GRANTED = "granted";
export const STARTTIME = "startTime";
export const ENDTIME = "endTime";
export const UID = "uid";
export const WEEK_DAYS = "weekDays";
export const WEEK_END = "weekEnd";
export const DELOS_STOP_ACTIVITY = "delos_stop_activity";
export const AGATA_STOP_ACTIVITY = "agata_stop_activity";
export const REHOBOAM_STOP_ACTIVITY = "rehoboam_stop_activity";
export const REMOTE_CONFIG_UPDATED_TIME = "remote_config_updated_time";
export const OPTIONS = "options";
export const IS_NEW = "isNew";
export const REMOTE_CONFIG_OBJECT = "remoteConfigDBObject";
export const BLOCKER_IS_ACTIVE = "blockerIsActive";
export const MAX_USER_LEVEL_NAME = "maxUserLevelName";
export const NO_ROLE_ASSIGNED = "no role assigned";

export const allowedWorkingTimeText = (startTime, endTime) =>
  `start at ${startTime} ends at start at ${endTime}`;

export const maxUserLevelNameText = (maxUserLevelName) =>
  maxUserLevelName ? (
    <div className="bold-text">{maxUserLevelName}</div>
  ) : (
    <div className="red-text">{NO_ROLE_ASSIGNED}</div>
  );

export const weekDaysAllowedText = (weekDays) =>
  weekDays === true ? `${WEEK_DAYS} allowed` : `${WEEK_DAYS} NOT allowed`;

export const weekEndsAllowedText = (weekEnds) =>
  weekEnds === true ? `${WEEK_END} allowed` : `${WEEK_END} NOT allowed`;
