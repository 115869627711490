import {
  AUTO_LOGIN_TRY_USED,
  PERMISSIONS,
  USER_DATA,
} from './constantsAuthStore';

export const getCurrentUserValue = (state) => {
  return state?.userData?.user?.email ? state.userData.user.email : null;
};

export const getCurrentUserIDValue = (state) => {
  return state?.userData?.user?.uid ? state.userData.user.uid : null;
};

export const getAutoLoginTryUsedValue = (state) => {
  return state?.[USER_DATA]?.[AUTO_LOGIN_TRY_USED]
    ? state[USER_DATA][AUTO_LOGIN_TRY_USED]
    : false;
};

export const getCurrentUserPermissionsLevel = (state) => {
  return state?.[USER_DATA]?.[PERMISSIONS] ? state.userData[PERMISSIONS] : -1;
};
