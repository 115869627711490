import { SPINNER_ON, SPINNER_OFF } from "./constantsSpinnerStore";

const initialState = {
  status: SPINNER_OFF,
};

export const spinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_ON:
      return { ...state, status: SPINNER_ON };
    case SPINNER_OFF:
      return { ...state, status: SPINNER_OFF };
    default:
      return state;
  }
};
