import {
  SET_ERROR_MESSAGE,
  CLEAR_ERROR_MESSAGE,
  CLEAR_FORCE_SIGN_OUT,
  SET_FORCE_SIGN_OUT,
} from "./constantsErrorMessageStore";

export function setErrorMessage(errorMessage) {
  return {
    type: SET_ERROR_MESSAGE,
    payload: errorMessage,
  };
}

export function clearErrorMessage() {
  return {
    type: CLEAR_ERROR_MESSAGE,
    payload: "",
  };
}

export function setForceSignOutAction() {
  return {
    type: SET_FORCE_SIGN_OUT,
    payload: true,
  };
}

export function clearForceSignOutAction() {
  return {
    type: CLEAR_FORCE_SIGN_OUT,
    payload: false,
  };
}
