import './users.css';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import {
  getUsers,
  convertSnapshotToUserPremissionObject,
} from './../../service/Firebase/userList.helper.firebase';
import { setForceUpdateStore } from './../../stores/BrainStore/BrainsAction';
import { getForceUpdate } from './../../stores/BrainStore/BrainsStoreHelper';
import UsersList from './UsersList/UsersList';

const Users = (props) => {
  const [usersList, setUsersList] = useState([]);

  const { forceUpdate } = props;

  useEffect(() => {
    let forUpdateValue = props.forceUpdate + 1;
    props.setForceUpdate(forUpdateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchUsersList() {
      const result = await getUsers();

      const newUserList = Object.keys(result).map((key) => {
        const userObject = convertSnapshotToUserPremissionObject(
          key,
          result[key]
        );
        return userObject;
      });

      setUsersList(newUserList);
    }

    fetchUsersList();
  }, [forceUpdate, setUsersList]);

  return (
    <div className='container-fluid text-center mt-5 w-50' data-testid='Users'>
      <div className='section-title'>Users</div>
      <UsersList usersList={usersList} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setForceUpdate: (forceUpdate) => {
      dispatch(setForceUpdateStore(forceUpdate));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    forceUpdate: getForceUpdate(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
