import React from 'react';
import './customersList.css';
import Customer from '../Customer/Customer';
import { DATA } from '../../../constants/constants.hostResponseObject';
import { useNavigate } from 'react-router';
import { EDIT, ADVERT, CUSTOMERS_ROUTE } from '../../../constants/constants.routes';

const CustomersList = (props) => {
  let navigate = useNavigate();

  const customerEditAction = (event, customerElement) => {
    event.stopPropagation();
    navigate(CUSTOMERS_ROUTE + EDIT + '/' + customerElement.id, { replace: true });
  };

  const customerAdvertAction = (customerElement) => {
    navigate(CUSTOMERS_ROUTE + ADVERT + '/' + customerElement.id, { replace: true });
  };

  return (
    <React.Fragment>
      <div data-testid='CustomersList'>
        {props.customersList[DATA].map((customerElement) => {
          return (
            <Customer
              customerElement={customerElement}
              key={customerElement.id}
              customerEditAction={customerEditAction}
              customerAdvertAction={customerAdvertAction}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default CustomersList;
