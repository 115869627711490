import { SUCCESS_MESSAGE, TYPE } from "../../constants/constans.store";
import { SET_SUCCESS_MESSAGE, CLEAR_SUCCESS_MESSAGE } from "./constantsSuccessMessageStore";

const initialState = {
  [SUCCESS_MESSAGE]: "",
  component: "",
};

export const successMessageReducer = (state = initialState, action) => {
  switch (action[TYPE]) {
    case SET_SUCCESS_MESSAGE:
      return { ...state, [SUCCESS_MESSAGE]: action.payload };
    case CLEAR_SUCCESS_MESSAGE:
      return { ...state, [SUCCESS_MESSAGE]: action.payload };
    default:
      return state;
  }
};
