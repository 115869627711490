export const STATUS = 'status';
export const RESULT = 'result';
export const VALUE = 'value';
export const MINLENGTH = 'minLength';
export const MAXLENGTH = 'maxLength';
export const FIELDNAME = 'fieldName';
export const USERFIELDNAME = 'userFieldName';
export const REMOVELEADINGZEROS = 'removeLeadingZeros';
export const MINVALUE = 'minValue';
export const MAXVALUE = 'maxValue';

