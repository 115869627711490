import React from 'react';
import { useNavigate } from 'react-router';
import { setCurrentUserStore } from '../../stores/AuthStore/AuthAction';
import { connect } from 'react-redux';
import {
  setSpinnerStatusOn,
  setSpinnerStatusOff,
} from '../../stores/SpinnerStore/SpinnerAction';
import { getAuth, signOut } from 'firebase/auth';
import { AUTOLOGIN, CURRENT_USER, NO } from './../../constants/constants';
import { useParams } from 'react-router-dom';
import { PERMISSIONS } from '../../stores/AuthStore/constantsAuthStore';

const SignOut = (props) => {
  let navigate = useNavigate();
  let { forced } = useParams();

  const signOutFunction = async () => {
    props.setSpinnerOn();

    const auth = getAuth();

    signOut(auth)
      .then(() => {
        window.sessionStorage.setItem(AUTOLOGIN, NO);
        window.sessionStorage.setItem(CURRENT_USER, null);
        window.sessionStorage.setItem(PERMISSIONS, null);
        props.setCurrentUser(null);
        props.setSpinnerOff();
        navigate('/', { replace: true });
      })
      .catch((error) => {
        props.setSpinnerOff();
      });
  };

  if (forced) {
    signOutFunction();
  }

  return (
    <div className='container-fluid text-center mt-5 w-25' data-testid="SignOut">
      <button
        type='button'
        id='logoutButton'
        className='btn btn-primary btn-lg btn-block'
        onClick={signOutFunction}
      >
        Sign Out
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (userData) => {
      dispatch(setCurrentUserStore(userData));
    },
    setSpinnerOn: () => {
      dispatch(setSpinnerStatusOn());
    },
    setSpinnerOff: () => {
      dispatch(setSpinnerStatusOff());
    },
  };
};

export default connect(null, mapDispatchToProps)(SignOut);
