import React, { useEffect, useState, useRef } from 'react';
import './customerEdit.css';
import { connect } from 'react-redux';
import {
  setUserForEditStore,
  setForceUpdateStore,
} from './../../../stores/BrainStore/BrainsAction';
import { getForceUpdate } from './../../../stores/BrainStore/BrainsStoreHelper';
import {
  getErrorMessageText,
  setErrorMessageForShow,
} from './../../../stores/ErrorMessageStore/ErrorMessageStoreHelper';
import {
  getSuccessMessage,
  setSuccessMessageForShow,
} from './../../../stores/SuccessMessageStore/SuccessMessageStoreHelper';
import {
  EDIT_CUSTOMER_TITLE,
  NEW_CUSTOMER_TITLE,
  SAVE_BUTTON,
} from './../../../constants/constants.titles';
import { TEXT, NUMBER, TYPE } from '../../../constants/constants';
import {
  MAXLENGTH,
  MINLENGTH,
  REMOVELEADINGZEROS,
  USERFIELDNAME,
} from '../../../constants/constants.validationResult';
import { validateField } from '../../../service/Validator/validate.field';
import { IS_NEW } from '../../../constants/constants.datamodel';
import { isCustomerValid } from '../../../service/Models/Customer.model';
import {
  CUSTOMER_NAME,
  GOOGLE_ID,
} from '../../../constants/constants.customer';

const CustomerEdit = (props) => {
  const { isNew, saveButtonClick } = props.customerData;

  const [customer, setCustomer] = useState({[CUSTOMER_NAME]:"", [GOOGLE_ID]:""});
  const nameInputRef = useRef(null);
  const googleIDInputRef = useRef(null);

  useEffect(() => {
    if (isNew === false) {
      setCustomer({ ...customer, ...props.customerData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkField = (fieldRef, options) => {
    validateField(
      fieldRef,
      options,
      props.setSuccessMessage,
      props.setErrorMessage
    );

    const updatedCustomer = Object.assign({});
    updatedCustomer[fieldRef.current.getAttribute('name')] =
      fieldRef.current.value;

    setCustomer({
      ...customer,
      ...updatedCustomer,
    });
  };

  return (
    <div
      className='container-fluid text-center mt-5 w-50'
      data-testid='CustomerEdit'
    >
      <b>{customer[IS_NEW] ? NEW_CUSTOMER_TITLE : EDIT_CUSTOMER_TITLE}</b>
      <br />
      <div className='mt-2'>
        <div>
          <div className='formElement'>
            <span>Company name:</span>
            <input
              type='text'
              ref={nameInputRef}
              name='customer_name'
              value={customer[CUSTOMER_NAME]}
              onChange={() => {
                checkField(nameInputRef, {
                  [MINLENGTH]: 5,
                  [MAXLENGTH]: 25,
                  [USERFIELDNAME]: 'Company name',
                  [TYPE]: TEXT,
                });
              }}
            />
          </div>
          <div className='formElement'>
            AdWords Google ID#:
            <input
              type='text'
              ref={googleIDInputRef}
              value={customer[GOOGLE_ID]}
              name='cid'
              onChange={() => {
                checkField(googleIDInputRef, {
                  [MINLENGTH]: 8,
                  [MAXLENGTH]: 8,
                  [USERFIELDNAME]: 'AdWords Google ID',
                  [REMOVELEADINGZEROS]: true,
                  [TYPE]: NUMBER,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <button
          onClick={() => {
            saveButtonClick();
          }}
          disabled={!isCustomerValid(customer)}
        >
          {SAVE_BUTTON}
        </button>
      </div>
      <div className='p-3'>
        {
          /* istanbul ignore next */
          props.errorMessage && props.errorMessage.length >= 0 ? (
            <div className='alert alert-danger' role='alert'>
              {props.errorMessage}
            </div>
          ) : (
            ''
          )
        }
        {
          /* istanbul ignore next */
          props.successMessage && props.successMessage.length >= 0 ? (
            <div className='alert alert-success' role='alert'>
              {props.successMessage}
            </div>
          ) : (
            ''
          )
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserForEdit: (user) => {
      dispatch(setUserForEditStore(user));
    },
    setForceUpdate: (forceUpdate) => {
      dispatch(setForceUpdateStore(forceUpdate));
    },
    setErrorMessage: (errorMessage) => {
      setErrorMessageForShow(dispatch, errorMessage);
    },
    setSuccessMessage: (successMessage) => {
      setSuccessMessageForShow(dispatch, successMessage);
    },
  };
};

const mapStateToProps = (state) => {
  return {
    forceUpdate: getForceUpdate(state),
    errorMessage: getErrorMessageText(state),
    successMessage: getSuccessMessage(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
