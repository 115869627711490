import React, { useState, useEffect } from 'react';
import { getHostResponseObject } from '../../../stateObjects/hostResponse.model';
import { MESSAGE } from '../../../constants/constants.hostResponseObject';
import SignOut from '../../SignOut/SignOut';

const CheckStatus = (props) => {
  const hostResponseObject = getHostResponseObject();
  const [agataStatus, setAgataStatus] = useState(hostResponseObject);

  useEffect(() => {
    setAgataStatus({ ...hostResponseObject, ...props.checkStatusData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkStatusData]);

  return (
    <React.Fragment>
      {props.forceSignOut === true ? (
        <SignOut />
      ) : (
        <div
          className='container-fluid text-center mt-5 w-50'
          data-testid='CheckStatus'
        >
          CheckStatus {props.host}: <strong>{agataStatus[MESSAGE]}</strong>
        </div>
      )}
    </React.Fragment>
  );
};

export default CheckStatus;
