import { FORCE_SIGN_OUT } from "../../constants/constants.hostResponseObject";
import {
  setErrorMessage,
  clearErrorMessage,
  setForceSignOutAction,
} from "./ErrorMessageAction";

export const getErrorMessageText = (state) => {
  return state.errorMessage ? state.errorMessage.errorMessage : "";
};

export const setErrorMessageForShow = (dispatch, errorMessage) => {
  dispatch(setErrorMessage(errorMessage));
  setTimeout(() => {
    dispatch(clearErrorMessage());
  }, 8000);
};

export const getForceSignOutStore = (state) => {
  return state.errorMessage[FORCE_SIGN_OUT] ? state.errorMessage[FORCE_SIGN_OUT] : false;
};

export const setForceSignOutStore = (dispatch) => {
  dispatch(setForceSignOutAction());
};
