export const LANDING = "/";
export const AUTH_SIGNIN = "/auth";
export const AUTH_SIGNOUT = "/signout";
export const CUSTOMERS_ROUTE = "/customers";
export const USERS = "/users";
export const REMOTE_CONFIG = "/remoteconfig";
export const PERMISSIONS_HAS_NOT_GRANTED = "/permissions_has_not_been_granted";
export const NEW = "new";
export const EDIT = "/edit";
export const ADVERT = "/advert";
export const AGATA = "agata";
export const REHOBOAM = "rehoboam";
export const FORCED = "forced";
