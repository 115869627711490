import React from "react";
import "./userList.css";
import User from "../User/User";
import { USERS } from "../../../constants/constants.routes";
import { useNavigate } from "react-router";
import { setUserForEditStore } from './../../../stores/BrainStore/BrainsAction';
import { connect } from 'react-redux';

const UsersList = (props) => {
  let navigate = useNavigate();

  const userClickAction = (userElement) => {
    props.setUserForUpdate(userElement);

    navigate(USERS + "/" + userElement.userEmail, { replace: true });
  };

  return (
    <React.Fragment>
      {props.usersList.map((userElement) => {
        return (
          <User userElement={userElement} key={userElement.uid} userClickAction={userClickAction} />
        );
      })}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserForUpdate: (user) => {
      dispatch(setUserForEditStore(user));
    },
  };
};

export default connect(null, mapDispatchToProps)(UsersList);
