import {
  AUTH_SET_CURRENT_USER,
  AUTH_SET_AUTOLOGIN_TRY_USED,
  AUTH_SET_CURRENT_USER_PERMISSIONS,
  USER,
  AUTO_LOGIN_TRY_USED,
  PERMISSIONS,
} from './constantsAuthStore';

const initialState = {
  [USER]: null,
  [AUTO_LOGIN_TRY_USED]: false,
  [PERMISSIONS]: null,
};

export const authUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_CURRENT_USER:
      return { ...state, [USER]: action.payload };
    case AUTH_SET_AUTOLOGIN_TRY_USED:
      return { ...state, autoLoginTryUsed: action.payload };
    case AUTH_SET_CURRENT_USER_PERMISSIONS:
      return { ...state, [PERMISSIONS]: action.payload };
    default:
      return state;
  }
};
