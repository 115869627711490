import React from 'react';
import './user.css';
import {
  ENDTIME,
  STARTTIME,
  WEEK_DAYS,
  WEEK_END,
  allowedWorkingTimeText,
  maxUserLevelNameText,
  weekDaysAllowedText,
  weekEndsAllowedText,
} from '../../../constants/constants.datamodel';

const User = (props) => {
  return (
    <React.Fragment>
      <div
        key={props.userElement.uid}
        className='formLine'
        onClick={() => props.userClickAction(props.userElement)}
        data-testid='User'
      >
        <div>{props.userElement.userEmail}</div>
        <div>{maxUserLevelNameText(props.userElement.maxUserLevelName)}</div>

        <div>
          {allowedWorkingTimeText(
            props.userElement[STARTTIME],
            props.userElement[ENDTIME]
          )}
        </div>
        <div>{weekDaysAllowedText(props.userElement[WEEK_DAYS])}</div>
        <div>{weekEndsAllowedText(props.userElement[WEEK_END])}</div>
      </div>
    </React.Fragment>
  );
};

export default User;
