import React from 'react';
import { useNavigate } from 'react-router';
import CustomerEdit from '../CustomerEdit/CustomerEdit';
import { useParams } from 'react-router-dom';
import { CUSTOMERS_ROUTE, NEW } from '../../../constants/constants.routes';
import { CUSTOMER_NAME, GOOGLE_ID } from '../../../constants/constants.customer';

const CustomersEditWrapper = (props) => {
  let { customerId } = useParams();

  let navigate = useNavigate();

  const isNewFunction = () => {
    return customerId === NEW ? true : false;
  };

  const saveButtonClick = async () => {
    navigate(CUSTOMERS_ROUTE, { replace: true });
  };

  const propsCustomerEdit = {
    customerId,
    isNew: isNewFunction(),
    [CUSTOMER_NAME]:'test_name',
    [GOOGLE_ID]: 12345678,
    saveButtonClick: () => saveButtonClick(),
  };

  return (
    <React.Fragment>
      <div data-testid='CustomersEditWrapper'>
        <CustomerEdit customerData={propsCustomerEdit} />
      </div>
    </React.Fragment>
  );
};

export default CustomersEditWrapper;
