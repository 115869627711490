import { NOTICE_MESSAGE } from "../../constants/constans.store";
import { setNoticeMessage, clearNoticeMessage } from "./NoticeMessageAction";

export const getNoticeMessage = (state) => {
  return state[NOTICE_MESSAGE] ? state[NOTICE_MESSAGE][NOTICE_MESSAGE] : "";
};

export const setNoticeMessageForShow = (dispatch, noticeMessage) => {
  dispatch(setNoticeMessage(noticeMessage));
  setTimeout(() => {
    dispatch(clearNoticeMessage());
  }, 5000);
};
