import { STATUS, RESULT, VALUE } from "../../constants/constants.validationResult";

export const ValidationResultObject = {
  [RESULT]: false,
  [VALUE]: "",
  [STATUS]: "",
};

export const getNewValidationResultObjectObject = () => {
  return Object.assign({}, ValidationResultObject);
};
