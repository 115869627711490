import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./components/App/App";
import { Provider } from "react-redux";
import { DEVELOPMENT } from "./constants/constants";
import { createStore } from "./stores/store.helpers";

document.title = "Minority Report";

if (process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT) {
  document.title = document.title + ": " + DEVELOPMENT;
}

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_AUTOLOGIN) {
  if (!window.sessionStorage.getItem("AutoLogin")) {
    window.sessionStorage.setItem("AutoLogin", process.env.REACT_APP_AUTOLOGIN);
  }
}

const appStore = createStore();

root.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
