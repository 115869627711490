export const getURLparams = (url, keys) => {
  const paramsArray = url.split("/").slice(2);

  const urlParamsObject = {};

  paramsArray.forEach((entry, index) => {
    const key = keys[index];
    urlParamsObject[key] = entry;
  });

  return urlParamsObject;
};
