import { SPINNER_ON, SPINNER_OFF } from "./constantsSpinnerStore";

export function setSpinnerStatusOn() {
  return {
    type: SPINNER_ON,
    payload: SPINNER_ON,
  };
}

export function setSpinnerStatusOff() {
  return {
    type: SPINNER_OFF,
    payload: SPINNER_OFF,
  };
}
