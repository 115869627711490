import {
  MINLENGTH,
  MAXLENGTH,
  USERFIELDNAME,
} from '../../constants/constants.validationResult';
import { getNewValidationResultObjectObject } from '../Models/ValidatorResult.model';

export const textValidator = (value, options) => {
  const validationResultObjectObject = getNewValidationResultObjectObject();

  if (!value) {
    validationResultObjectObject.status =
      'No value has been provided for ' + options[USERFIELDNAME];
    return validationResultObjectObject;
  }

  if (options[MINLENGTH] && value.length < options[MINLENGTH]) {
    validationResultObjectObject.status =
      'Length of value is less then ' + options[MINLENGTH];
    return validationResultObjectObject;
  }

  if (options[MAXLENGTH] && value.length > options[MAXLENGTH]) {
    validationResultObjectObject.status =
      'Length of value is more then ' + options[MAXLENGTH];
    return validationResultObjectObject;
  }

  validationResultObjectObject.status =
    'Prodided value is correct for ' + options[USERFIELDNAME];

  validationResultObjectObject.result = true;

  return validationResultObjectObject;
};
