import { configureStore } from "@reduxjs/toolkit";
import { authUserReducer } from "./AuthStore/AuthUserReducer";
import { spinnerReducer } from "./SpinnerStore/SpinnerReducer";
import { brainReducer } from "./BrainStore/BrainsReducer";
import { errorMessageReducer } from "./ErrorMessageStore/ErrorMessageReducer";
import { successMessageReducer } from "./SuccessMessageStore/SuccessMessageReducer";
import { noticeMessageReducer } from "./NoticeMessageStore/NoticeMessageReducer";
import {
  BRAIN_STATE,
  ERROR_MESSAGE,
  NOTICE_MESSAGE,
  SPINNER,
  SUCCESS_MESSAGE,
} from "../constants/constans.store";

export function storeState(state) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (e) {
    console.log("Error while storing the state:", e);
  }
}

export function createStore() {
  const appStore = configureStore({
    reducer: {
      userData: authUserReducer,
      [SPINNER]: spinnerReducer,
      [ERROR_MESSAGE]: errorMessageReducer,
      [SUCCESS_MESSAGE]: successMessageReducer,
      [BRAIN_STATE]: brainReducer,
      [NOTICE_MESSAGE]: noticeMessageReducer,
    },
  });

  return appStore;
}
