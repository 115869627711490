import { PAYLOAD, TYPE } from "../../constants/constans.store";
import { SET_SUCCESS_MESSAGE, CLEAR_SUCCESS_MESSAGE } from "./constantsSuccessMessageStore";

export function setSuccessMessage(successMessage) {
  return {
    [TYPE]: SET_SUCCESS_MESSAGE,
    [PAYLOAD]: successMessage,
  };
}

export function clearSuccessMessage() {
  return {
    [TYPE]: CLEAR_SUCCESS_MESSAGE,
    [PAYLOAD]: "",
  };
}
